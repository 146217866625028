<template>
  <div
    v-if="showEdit"
    class="modal modal__right"
    :class="{ show: showEdit === true }"
    :style="{ display: 'block' }"
    id="editModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#editModal"
              class="modal__close modal__close--black"
              @click="$emit('close')"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Edit Group</h5>
        </div>
        <div class="modal__body">
            <form @submit="saveEditForm">
                <div class="row">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group Name</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group Name"
                        v-model="editForm.groupName"
                        v-bind:class="{
                          'input-required': groupFormErr.groupName,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group Customer Type</label>
                      <select
                        v-model="editForm.clientRoleKey"
                        class="select select--lg"
                        @change="
                          getCustomFormFields('Groups', $event.target.value)
                        "
                        v-bind:class="{
                          'input-required': groupFormErr.clientRoleKey,
                        }"
                      >
                        <option
                          v-for="item in groupClientTypes"
                          v-bind:key="item.customerTypeKey"
                          :value="item.customerTypeKey"
                        >
                          {{ item.clientTypeName }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group ID</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group ID"
                        v-model="editForm.uniqueGroupId"
                        v-bind:class="{
                          'input-required': groupFormErr.uniqueGroupId,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form__item">
                      <!-- <label class="form__label">Group Email</label> -->
                      <div style="padding:0px !important" class="col-12">
                        <label style="padding:0px !important" class="form__label col-4">Email</label>
                        <label
                          v-if="requiredEmail"
                          style="padding:0px !important"
                          class="form__label col-8 text-danger"
                        >Email is required</label>
                      </div>
                      <input
                        type="email"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group email"
                        v-model="editForm.groupEmailAddress"
                        v-bind:class="{
                          'input-required': groupFormErr.groupEmailAddress,
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group phone number</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group phone number"
                        v-model="editForm.groupPhoneNumber"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group Mobile number</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group mobile number"
                        v-model="editForm.groupMobileNumber"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label">Group Type</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter Group Type"
                        readonly
                        value="Group"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Group loan cycle</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Group loan cycle"
                      v-model="editForm.loanCycle"
                    />
                  </div>
                </div> -->
                </div>

                <ul id="accordion" class="mb5">
                  <div v-if="group != null" class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#cggroupMembers"
                      aria-expanded="false"
                      aria-controls="cggroupMembers"
                    >
                      <h5
                        class="
                          w-100
                          mb-0
                          d-flex
                          align-items-center
                          justify-content-between
                        "
                      >
                        Group Members
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="cggroupMembers"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div
                          class="row"
                          v-for="item in group.memberKeyArray"
                          v-bind:key="item"
                        >
                          <div class="form__item col-8">
                            <input
                              type="text"
                              :value="group.memberNames[item]"
                              class="input form__input form__input--lg"
                              disabled
                            />
                          </div>
                          <div class="form__item col-3">
                            <button
                              v-on:click="deleteMemberFromGroup(item)"
                              type="button"
                              class="
                                button
                                page__body__header__button
                                button--grey
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                              <img
                                src="../../../../assets/img/delete.svg"
                                alt=""
                                srcset=""
                              />
                            </button>
                          </div>
                        </div>
                        <div class="form__item">
                          <label class="form__label">Select Group Member</label>
                          <select
                            class="select select--lg"
                            v-model="selectedCustomerKey"
                          >
                            <option
                              v-for="item in allCustomers"
                              v-bind:key="item.customerKey"
                              :value="item.customerKey"
                            >
                              {{ item.customerNameWithID }}
                              <!-- {{ item.customerLastName }} -->
                            </option>
                          </select>
                        </div>
                        <div class="form__item" id="branch-area"></div>
                        <div class="text-right mt-3">
                          <button
                            @click="validateMultipleCustomer(selectedCustomerKey)"
                            type="button"
                            :disabled="enabled"
                            class="
                              d-flex
                              align-items-center
                              ml-auto
                              button button--grey
                              text--600 text--black text--small text--capital
                            "
                          >
                            {{enabled ? "Please wait..." : "Add customer to group" }}
                            <ion-icon
                              name="add-outline"
                              v-if="!enabled"
                              class="text--600 text--medium ml-2"
                            ></ion-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="group != null" class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#cggroupRoles"
                      aria-expanded="false"
                      aria-controls="cggroupRoles"
                    >
                      <h5
                        class="
                          w-100
                          mb-0
                          d-flex
                          align-items-center
                          justify-content-between
                        "
                      >
                        Group Roles
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="cggroupRoles"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div
                          v-for="item in group.memberRoles"
                          v-bind:key="'g' + item.clientKey"
                        >
                          <div
                            class="row align-items-center"
                            v-if="
                              item.groupRoleNameKey != null &&
                              item.clientKey != null
                            "
                          >
                            <div class="col-5">
                              <div class="form__item">
                                <label class="form__label">Group Role</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  :disabled="true"
                                  :value="item.groupRoleName"
                                />
                              </div>
                            </div>
                            <div class="col-5">
                              <div class="form__item">
                                <label class="form__label">Members</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  :disabled="true"
                                  :value="item.customerName"
                                />
                              </div>
                            </div>
                            <div class="col-2">
                              <button
                                @click="
                                  removeGroupRole(
                                    item.clientKey,
                                    item.groupRoleNameKey
                                  )
                                "
                                type="button"
                                class="
                                  button button--cancel
                                  text--500 text--small text--capital
                                "
                              >
                                delete
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="row align-items-center">
                          <div class="col-5">
                            <div class="form__item">
                              <label class="form__label">Group Roles</label>
                              <select
                                v-model="groupRoleAddModel.groupRoleNameKey"
                                class="select select--lg"
                              >
                                <option
                                  v-for="item in allGroupRoleNames"
                                  v-bind:key="item.groupRoleNameKey"
                                  :value="item.groupRoleNameKey"
                                >
                                  {{ item.groupRoleName }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="form__item">
                              <label class="form__label">Members</label>
                              <select
                                v-model="groupRoleAddModel.clientKey"
                                class="select select--lg"
                              >
                                <option
                                  v-for="item in group.memberKeyArray"
                                  v-bind:key="item"
                                  :value="item"
                                >
                                  {{ group.memberNames[item] }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="text-right mt-3">
                          <button
                            @click="addGroupRole()"
                            type="button"
                            class="
                              d-flex
                              align-items-center
                              ml-auto
                              button button--grey
                              text--600 text--black text--small text--capital
                            "
                            :disabled="
                              groupRoleAddModel.groupRoleNameKey == null &&
                              groupRoleAddModel.clientKey == null
                            "
                          >
                            Add Group Role
                            <ion-icon
                              name="add-outline"
                              class="text--600 text--medium ml-2"
                            ></ion-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#cgaddress"
                    aria-expanded="false"
                    aria-controls="cgaddress"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Address
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="cgaddress" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="row">
                        <div class="col-12">
                          <div class="form__item">
                            <label class="form__label"
                              >Street Address - Line 1</label
                            >
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form__item">
                            <label class="form__label"
                              >Street Address - Line 2</label
                            >
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">City</label>
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter City"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >State/Province/Region</label
                            >
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter province"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Zip postal code</label>
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter Zip postal code"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Country</label>
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter country"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#cgassociated"
                      aria-expanded="false"
                      aria-controls="cgassociated"
                    >
                      <h5
                        class="
                          w-100
                          mb-0
                          d-flex
                          align-items-center
                          justify-content-between
                        "
                      >
                        Associated
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="cgassociated"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Branch</label>
                              <select
                                class="select--lg w-100"
                                v-model="editForm.assignedBranchKey"
                                @change="branchSelectChange($event)"
                                v-bind:class="{
                                  'input-required':
                                    groupFormErr.assignedBranchKey,
                                }"
                              >
                                <option disabled>Select branch</option>
                                <option
                                  v-for="item in allBranches"
                                  v-bind:key="item.branchKey"
                                  v-bind:value="item.branchKey"
                                >
                                  {{ item.branchName }} ({{ item.branchId }})
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Center</label>
                              <select
                                class="select--lg w-100"
                                v-model="editForm.assignedCentreKey"
                                v-bind:class="{
                                  'input-required':
                                    groupFormErr.assignedCentreKey,
                                }"
                              >
                                <option disabled>Select centre</option>
                                <option
                                  v-for="item in branchCenter"
                                  v-bind:key="item.centerKey"
                                  v-bind:value="item.centerKey"
                                >
                                  {{ item.centerName }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Account Officer</label>
                              <select
                                class="select--lg w-100"
                                v-model="editForm.assignedUserKey"
                                v-bind:class="{
                                  'input-required':
                                    groupFormErr.assignedUserKey,
                                }"
                              >
                                <option disabled>Select Account Officer</option>
                                <option
                                v-for="item in accountOfficers"
                                  :key="item.userKey"
                                  :value="item.userKey"
                                >
                                  {{ item.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="modal__accordion"
                    v-for="(set, index) in editForm.customFieldSetFieldsAndValue"
                    :key="set.fieldSetKey"
                  >
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      :data-target="`#ccSet-${set.fieldSetKey}${index}`"
                      aria-expanded="false"
                      aria-controls="ccprofileNote"
                    >
                      <h5
                        class="
                          w-100
                          mb-0
                          d-flex
                          align-items-center
                          justify-content-between
                        "
                      >
                        {{ set.fieldSetName }}
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      :id="`ccSet-${set.fieldSetKey}${index}`"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="row">
                          <div class="col-6" v-for="(field, i) in set.fields" :key="field.fieldKey">
                            <div v-if="field.dataType == 'Number'" class="form__item">
                              <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                              <input
                                type="number"
                                class="input form__input form__input--lg"
                                placeholder="Enter Field value"
                                :required="field.isRequired"
                                @input="editAccountValidateNumber(field.value, index, i)"
                                v-model="field.value"
                              />
                            </div>
                            <div v-if="field.dataType == 'Checkbox'" class="form__item">
                              <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                                <input
                                  type="checkbox"
                                  id="checkbox"
                                  class="input form__input form__input--lg"
                                  placeholder="Enter Field value"
                                  :required="field.isRequired"
                                  v-model="field.value"
                                />
                            </div>
                            <div v-if="field.dataType == 'Free text'" class="form__item">
                              <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                placeholder="Enter Field value"
                                :required="field.isRequired"
                                v-model="field.value"
                              />
                            </div>
                            <div v-if="field.dataType == 'Date'" class="form__item">
                              <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                              <input
                                type="date"
                                class="input form__input form__input--lg"
                                placeholder="Enter Field value"
                                :required="field.isRequired"
                                v-model="field.value"
                              />
                            </div>
                            <div v-if="field.dataType == 'Selection'" class="form__item">
                              <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                              <select
                                class="select select--lg"
                                :required="field.isRequired"
                                v-model="field.value"
                              >
                                <option value="" disabled selected>Select Field Value</option>
                                <option
                                  v-for="filedValue in field.selectionFieldValues"
                                  :key="filedValue.customFieldSelectionKey"
                                  :value="filedValue.value"
                                >
                                  {{ filedValue.value }}
                                </option>
                              </select>
                            </div>
                            <div
                              v-show="field.value"
                              v-if="['customer link', 'group link', 'user link'].includes(field.dataType.toLowerCase())"
                              class="form__item"
                            >
                              <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                              <multiselect
                                v-model="field.value"
                                id="ajax"
                                label="name"
                                track-by="name"
                                :placeholder="`Type to search ${field.dataType.split(' ')[0]}`"
                                open-direction="bottom"
                                :options="field.searchValue ? field.searchValue : searchValues"
                                :multiple="false"
                                :searchable="true"
                                :internal-search="false"
                                :clear-on-select="false"
                                :options-limit="300"
                                :limit="5"
                                :max-height="800"
                                :show-no-results="false"
                                :hide-selected="true"
                                @search-change="editAccountQuerySearch($event, index, i)"
                              >
                                <template slot="tag" slot-scope="{ option, remove }">
                                  <span class="custom__tag">
                                    <span>{{ option.name }}</span>
                                    <span class="custom__remove" @click="remove(option)"
                                      >❌</span
                                    >
                                  </span>
                                </template>
                              </multiselect>
                            </div>
                          </div>
                          <!-- <div
                            class="col-6"
                            v-for="form in set.customFields"
                            :key="form.customFieldKey"
                          >
                            <div class="form__item">
                              <label class="form__label">{{
                                form.customFieldName
                              }}</label>
                              <input
                                :type="
                                  form.dataType === 'Number'
                                    ? 'number'
                                    : form.dataType === 'Date'
                                    ? 'date'
                                    : form.dataType === 'Checkbox'
                                    ? 'checkbox'
                                    : 'text'
                                "
                                v-if="form.dataType !== 'Selection'"
                                :id="form.customFieldKey"
                                class="
                                  input input--right
                                  form__input form__input--lg
                                "
                                :placeholder="`Enter ${form.customFieldName}`"
                              />
                              <select
                                class="select--lg w-100"
                                v-if="form.dataType === 'Selection'"
                                :id="form.customFieldKey"
                              >
                                <option disabled selected value="null">
                                  Select {{ form.customFieldName }}
                                </option>
                                <option
                                  v-for="opt in form.customFieldSelections"
                                  :key="opt.customFieldSelectionKey"
                                  :value="opt.value"
                                >
                                  {{ opt.value }}
                                </option>
                              </select>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#cgprofileNote"
                      aria-expanded="false"
                      aria-controls="cgprofileNote"
                    >
                      <h5
                        class="
                          w-100
                          mb-0
                          d-flex
                          align-items-center
                          justify-content-between
                        "
                      >
                        Profile Note
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="cgprofileNote"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="form__item">
                          <textarea
                            cols="5"
                            rows="5"
                            class="input form__input"
                            placeholder="Profile Note"
                            v-model="editForm.groupNotes"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
                <div class="form__item">
                  <div class="form__action">
                    <button
                      type="submit"
                      class="
                        button
                        form__button form__button--lg
                        w-30
                        save-changes
                      "
                      v-if="!isSaving"
                    >
                      Save group
                    </button>
                    <a
                      role="button"
                      class="
                        button
                        form__button form__button--lg
                        w-25
                        modal__confirm__button modal__confirm__button--delete
                      "
                      v-if="isSaving"
                      :disabled="true"
                    >
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    </a>
                    <a
                      data-dismiss="modal"
                      role="button"
                      v-if="!isSaving"
                      class="
                        modal__close
                        form__action__text form__action__text--danger
                        text--capital
                        ml6
                      "
                      @click="$emit('close')"
                    >
                      cancel
                    </a>
                  </div>
                </div>
            </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import draggable from "vuedraggable";
import Vue from "vue";
import moment from "moment";
import branchesService from "@/core/services/organization-service/branches.service";
import centresService from "@/core/services/organization-service/centres.service";
import customerService from "@/core/services/general-setup-service/customer.service";
import customerTypeService from "@/core/services/general-setup-service/customer-type.service";
import groupService from "@/core/services/general-setup-service/group.service";
import Multiselect from "vue-multiselect";

//API Services
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import AccessApiService from "@/core/services/access.service";

import fieldSetService from "@/core/services/fieldset-service/fieldset.service";
// import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";

// import { delete } from "vue/types/umd";
export default Vue.extend({
  name: "groups",
  props: {
    showEdit: Boolean,
    groupFo: Object,
  },
  components: {
    Multiselect,
    // draggable,
  },
  data: function () {
    return {
      allBranches: [],
      allCentres: [],
      allGroupRoleNames: [],
      grNameDict: null,
      allCustomers: [],
      groupClientTypes: [],
      editForm: {
        groupKey: "",
        assignedBranchKey: "",
        assignedCentreKey: "",
        assignedUserKey: "",
        clientRoleKey: "",
        groupEmailAddress: "",
        groupName: "",
        groupPhoneNumber: "",
        uniqueGroupId: "",
        loanCycle: 0,
        groupMobileNumber: "",
        groupNotes: "",
        createdBy: null,
        groupClientRoles: [
          {
            groupRoleKey: "string",
            clientKey: "string",
            groupRoleNameKey: "string",
          },
        ],
        customFieldSetFieldsAndValue: [],
        customFieldValues: [],
      },
      groupFormErr: {
        groupKey: false,
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        clientRoleKey: false,
        groupEmailAddress: false,
        groupName: false,
        groupPhoneNumber: false,
        uniqueGroupId: false,
        loanCycle: false,
        groupMobileNumber: false,
        groupNotes: false,
        createdBy: false,
        groupClientRoles: false,
      },
      selectedCustomerKey: null,
      groupRoleAddModel: {
        groupRoleNameKey: null,
        clientKey: null,
      },
      exportData: [],
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
      isSaving: false,
      // customFields: [],
      isLoading: false,
      searchValues:[],
      group: "",
      enabled: false,
      branchCenter: [],
      accountOfficers: [],
      requiredEmail: false,
    };
  },
  watch: {
    groupFo: function(newVal) {
      if(newVal) {
          this.editGroup(newVal);
      }
    },
  },
  methods: {
    async getBranches() {
      try {
        this.isLoading = true;
        const res = await branchesService.get({
          branchName: null,
          branchState: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
          filters: [],
        });
        this.allBranches = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
            this.$emit('alertError', error);
      }
    },
    async getCentres() {
      this.allCentres = [];
      try {
        this.isLoading = true;
        "l", "pt", "a4";
        const res = await centresService.get({
          centreName: null,
          centreStatus: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
        });
        this.allCentres = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
            this.$emit('alertError', error);
      }
    },
    async getCustomers() {
      try {
        this.isLoading = true;
        const res = await customerService.getCustomers({
          customerTypeKey: null,
          pageIndex: 1,
          pageSize: 1000,
          filters: [],
        });
        this.allCustomers = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        if (e.response.status != 409) {
          const error = e.response.data.message;
          this.$emit('alertError', error);
        }
      }
    },
    async getCustomerTypes() {
      try {
        this.isLoading = true;
        const res = await customerTypeService.get({
          filter: {
            mode: "Or",
            filterLines: [],
          },
          pageIndex: 1,
          pageSize: 1000,
          clientType: "Group",
        });
        this.groupClientTypes = res.data.data.items.slice().sort((a, b) => {
            let textA = a.clientTypeName.toUpperCase();
            let textB = b.clientTypeName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.isLoading = false;
      } catch (e) {
        if (e.response.status != 409) {
          const error = e.response.data.message;
          this.$emit('alertError', error);
        }
      }
    },
    async getGroupRoleNames() {
      try {
        this.isLoading = true;
        const res = await groupService.getGroupRoleNames({ pageSize: 10000, pageIndex: 1 });
        this.allGroupRoleNames = res.data.data.data;
        let dictionary = Object.assign(
          {},
          ...this.allGroupRoleNames.map((x) => ({
            [x.groupRoleNameKey]: x.groupRoleName,
          }))
        );
        this.grNameDict = dictionary;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.$emit('alertError', error);
      }
    },
    async getCustomFormFields(type, key) {
      this.customFields = [];
      try {
        this.requiredEmail = this.groupTypes.find(item => key === item.customerTypeKey).requireEmailAddress;
        this.isLoading = true;
        const model = {
          customFieldSetType: type,
        };
        const res = await fieldSetService.getFormFieldsByType(model);
        this.customFields = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.$emit('alertError', error);
      }
    },
    editGroup(item) {
      this.getCustomFormFields("Groups", item.customerType.customerTypeKey);
      this.groupRoleAddModel = {
        groupRoleNameKey: null,
        clientKey: null,
      };
      this.clearForm();
      let group = {
        ...item,
        memberKeySet: new Set(),
        memberKeyArray: [],
        memberNames: {},
        memberRoles: [],
      };

      group.groupMembersRoles.forEach((e) => {
        group.memberKeySet.add(e.clientKey);
        // set name:  key => name
        group.memberNames[e.clientKey] = e.customerName;

        if (e.roles.length) {
          e.roles.forEach((role) => {
            group.memberRoles.push({
              groupRoleNameKey: role.groupRoleNameKey,
              groupRoleName: role.roleName,
              clientKey: e.clientKey,
              customerName: e.customerName,
            });
          });
        } else {
          group.memberRoles.push({
            groupRoleNameKey: null,
            groupRoleName: null,
            clientKey: e.clientKey,
            customerName: e.customerName,
          });
        }
      });
      group.memberKeyArray = Array.from(group.memberKeySet);
      this.group = group;
      this.editForm = {
        groupKey: item.groupKey,
        assignedBranchKey: item.assignedBranchKey,
        assignedCentreKey: item.assignedCentreKey,
        assignedUserKey: item.assignedUserKey,
        clientRoleKey: item.clientRoleKey,
        groupEmailAddress: item.groupEmailAddress,
        groupName: item.groupName,
        groupPhoneNumber: item.groupPhoneNumber,
        uniqueGroupId: item.uniqueGroupId,
        loanCycle: item.loanCycle,
        groupMobileNumber: item.groupMobileNumber,
        groupNotes: item.groupNotes,
        createdBy: item.createdBy,
        groupClientRoles: item.groupClientRoles,
        customFieldSetFieldsAndValue: group.customFieldSetFieldsAndValue,
      };
      this.branchSelectChange(this.editForm.assignedBranchKey)
    },
    editAccountValidateNumber(num, fieldSetIndex, fieldIndex) {
      if(isNaN(parseFloat(num))) {
        this.$emit("alertWarning", "Invalid input value, Number required.");
        this.editForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].value = 0;
      }
    },
    async editAccountQuerySearch(query, fieldSetIndex, fieldIndex) {
      let searchQuery = query;
      let searchArea =
        this.editForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].dataType.toLowerCase() == "customer link"
          ? "customer"
          : this.editForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].dataType.toLowerCase() == "group link"
          ? "group"
          : "user";

      try {
        const response = await ApiService.post(`GlobalSearch`,
          { searchQuery, searchCategory: searchArea }
        );
        this.editForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].searchValue = 
          response.data.data ? response.data.data : [];
        this.searchValues = response.data.data ? response.data.data : [];
      } catch(e) {
        this.editForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].searchValue = [];
      }
    },
    saveEditForm: function (e) {
      e.preventDefault();
      if (this.validategroupForm()) {
        try {
          this.isSaving = true;
          // this.editForm.groupClientRoles = this.group.memberRoles;
          if (!this.editForm.groupEmailAddress && this.requiredEmail) {
            this.alertWarning("Email field is required.");
            return;
          }
          this.editForm.members = [];
          this.editForm.updateField = {};
          this.editForm.updateField.values = [];
          this.editForm.updateField.customerKey = "";
          if(this.editForm.customFieldSetFieldsAndValue.length) {
            this.editForm.customFieldSetFieldsAndValue.forEach((item) => {
              for(let i = 0; i < item.fields.length; i++) {
                if(['customer link', 'group link', 'user link'].includes(item.fields[i].dataType.toLowerCase())) {
                  if(item.fields[i].value) {
                    const updateValue = item.fields[i].value;
                    item.fields[i].value = updateValue.linkKey;
                  }
                }
                let customObj = {
                  customFieldValue: item.fields[i].value,
                  customFieldKey: item.fields[i].fieldKey,
                  scoreAmount: 0,
                  indexInList: 0,
                  customFieldSetGroupIndex: 0,
                }
                this.editForm.updateField.values.push(customObj);
              }
            })
            this.editForm.updateField.customerKey = this.editForm.groupKey;
          }
          const memberRoleDict = {};
          this.group.memberRoles.forEach((row) => {
            if (!memberRoleDict.hasOwnProperty(row.clientKey)) {
              memberRoleDict[row.clientKey] = [];
            }
            if (row.groupRoleNameKey != null) {
              memberRoleDict[row.clientKey].push(row.groupRoleNameKey);
            }
          });
          for (const clientKey in memberRoleDict) {
            this.editForm.members.push({
              clientKey: clientKey,
              roleNameKeys: memberRoleDict[clientKey],
            });
          }
          groupService.updateGroup(this.editForm.groupKey, this.editForm).then(
            (res) => {
              this.clearForm();
              this.$emit('close');
              this.$emit("alertSuccess", "Group updated.");
            this.$emit("refresh");
              this.isSaving = false;
              this.group = res;
              this.group = null;
            },
            (err) => {
              this.isSaving = false;
              const error = err.response.data.data || err.response.data.message;
            this.$emit('alertError', error);
            }
          );
        } catch (err) {
          this.isSaving = false;
          const error = err.response.data.data || err.response.data.message;
            this.$emit('alertError', error);
        }
      } else {
        this.$emit('alertWarning', "Some form fields are required.");
      }
    },
    validateMultipleCustomer(key) {
      this.enabled = true;
      GeneralApiService.post(
        `Group/FindIfCustomerCanBeAddedToMultipleGroup?customerKey=${key}`)
        .then((response) => {
          if(response.data.status) {
            this.enabled = false;
            this.addMemberToGroup(key)
          } else {
            this.enabled = false;
            this.selectedCustomerKey = ""
            this.alertError("This customer already belongs to another group and cannot be added.")
          }
        })
        .catch(() => {
          this.enabled = false;
          this.selectedCustomerKey = ""
          this.alertError("This customer already belongs to another group and cannot be added.")
        });
    },
    addMemberToGroup(customerKey) {
      if (!this.group.memberKeySet.has(customerKey)) {
        const c = this.allCustomers.filter((x) => x.customerKey == customerKey);
        if (c.length > 0) {
          const customer = c[0];
          let name = `${customer.customerNameWithID}`;

          this.group.memberKeySet.add(customerKey);
          this.group.memberNames[customerKey] = name;
          this.group.memberRoles.push({
            groupRoleNameKey: null,
            groupRoleName: null,
            clientKey: customerKey,
            customerName: name,
          });
          this.group.memberKeyArray = Array.from(this.group.memberKeySet);
        }
        this.selectedCustomerKey = ""
      } else {
        this.$emit('alertWarning', "Member name already exist.");
      }
    },
    branchSelectChange(event) {
      const branchKey = event.target ? event.target.value : event;
      this.getCustomerAccountOfficer(branchKey);
      this.getBranchCenters(branchKey);
    },
    getCustomerAccountOfficer(key) {
      this.accountOfficers = [];
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${key}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      })
    },
    async getBranchCenters(key) {
      const model = {
        searchParam: null,
        branchKey: key,
        pageIndex: 1,
        pageSize: 100,
      };
      try {
        const res = await centresService.search(model);
        this.branchCenter = [];
        this.branchCenter = res.data.data.items;
        // this.isLoading = false;
      } catch (e) {
        // this.isLoading = false;
        const error = e.response.data.message;
        this.$emit('alertError', error);
      }
    },
    deleteMemberFromGroup(item) {
      this.group.memberKeySet.delete(item);
      delete this.group.memberNames[item];
      this.group.memberRoles = this.group.memberRoles.filter(
        (x) => x.clientKey != item
      );
      this.group.memberKeyArray = Array.from(this.group.memberKeySet);
    },
    addGroupRole() {
      if(this.groupRoleAddModel.groupRoleNameKey && this.groupRoleAddModel.clientKey) {
        let groupRoleName = this.allGroupRoleNames.find(role => role.groupRoleNameKey === this.groupRoleAddModel.groupRoleNameKey);
        this.group.memberRoles.forEach(item => {
          if(item.clientKey === this.groupRoleAddModel.clientKey) {
            item.groupRoleNameKey = this.groupRoleAddModel.groupRoleNameKey;
            item.groupRoleName = groupRoleName.groupRoleName;
          }
        })
        this.groupRoleAddModel.groupRoleNameKey = "";
        this.groupRoleAddModel.clientKey = "";
      }
      // if (
      //   this.groupRoleAddModel.groupRoleNameKey != null &&
      //   this.groupRoleAddModel.clientKey != null
      // ) {
      //   if (!(this.groupRoleAddModel.clientKey in this.group.memberNames)) {
      //     this.groupRoleAddModel = {
      //       groupRoleNameKey: null,
      //       clientKey: null,
      //     };
      //     return;
      //   }
      //   let exists = this.group.memberRoles.filter(
      //     (x) =>
      //       x.clientKey == this.groupRoleAddModel.clientKey &&
      //       x.groupRoleNameKey == this.groupRoleAddModel.groupRoleNameKey
      //   );
      //   if (exists.length > 0) {
      //     this.groupRoleAddModel = {
      //       groupRoleNameKey: null,
      //       clientKey: null,
      //     };
      //     return;
      //   }

      //   let cname = this.group.memberNames[this.groupRoleAddModel.clientKey];
      //   this.group.memberRoles.push({
      //     groupRoleNameKey: this.groupRoleAddModel.groupRoleNameKey,
      //     groupRoleName:
      //       this.grNameDict[this.groupRoleAddModel.groupRoleNameKey],
      //     clientKey: this.groupRoleAddModel.clientKey,
      //     customerName: cname,
      //   });
      //   this.groupRoleAddModel = {
      //     groupRoleNameKey: null,
      //     clientKey: null,
      //   };
      // }
    },
    removeGroupRole(clientKey, groupRoleNameKey) {
      let deleteRole = this.group.memberRoles.find(
        (role) => role.clientKey == clientKey && role.groupRoleNameKey == groupRoleNameKey
      )
      deleteRole.groupRoleNameKey = null;
      deleteRole.groupRoleName = null;
      const savedRole = this.group.memberRoles.filter(
        (x) =>
          x.clientKey != clientKey && x.groupRoleNameKey != groupRoleNameKey
      );
      this.group.memberRoles = [...savedRole, deleteRole]
    },
    clearForm() {
      this.group = null;
      this.requiredEmail = false
      this.editForm = {
        groupKey: "",
        assignedBranchKey: "",
        assignedCentreKey: "",
        assignedUserKey: "",
        clientRoleKey: "",
        groupEmailAddress: "",
        groupName: "",
        groupPhoneNumber: "",
        uniqueGroupId: "",
        loanCycle: 0,
        groupMobileNumber: "",
        groupNotes: "",
        createdBy: null,
        groupClientRoles: [
          {
            groupRoleKey: "string",
            clientKey: "string",
            groupRoleNameKey: "string",
          },
        ],
      };
      this.groupRoleAddModel = {
        groupRoleNameKey: null,
        clientKey: null,
      };
    },
    validategroupForm() {
      this.cleargroupFormValidation();
      if (
        this.editForm.groupName &&
        this.editForm.uniqueGroupId &&
        // this.editForm.groupEmailAddress &&
        this.editForm.clientRoleKey &&
        this.editForm.assignedBranchKey &&
        this.editForm.assignedCentreKey &&
        this.editForm.assignedUserKey
      ) {
        return true;
      }

      if (!this.editForm.groupName) {
        this.groupFormErr.groupName = true;
      }
      if (!this.editForm.uniqueGroupId) {
        this.groupFormErr.uniqueGroupId = true;
      }
      if (!this.editForm.groupEmailAddress) {
        this.groupFormErr.groupEmailAddress = true;
      }
      if (!this.editForm.clientRoleKey) {
        this.groupFormErr.clientRoleKey = true;
      }
      if (!this.editForm.assignedBranchKey) {
        this.groupFormErr.assignedBranchKey = true;
      }
      if (!this.editForm.assignedCentreKey) {
        this.groupFormErr.assignedCentreKey = true;
      }
      if (!this.editForm.assignedUserKey) {
        this.groupFormErr.assignedUserKey = true;
      }

      return false;
    },
    cleargroupFormValidation() {
      this.groupFormErr = {
        groupKey: false,
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        clientRoleKey: false,
        groupEmailAddress: false,
        groupName: false,
        groupPhoneNumber: false,
        uniqueGroupId: false,
        loanCycle: false,
        groupMobileNumber: false,
        groupNotes: false,
        createdBy: false,
        groupClientRoles: false,
      };
    },
    // alertSuccess(description) {
    //   this.alert.status = "success";
    //   this.alert.title = "Success";
    //   this.alert.description = description;
    //   this.alert.show = true;
    //   const self = this;
    //   setTimeout(function () {
    //     self.alert.show = false;
    //   }, 5000);
    // },
    // alertWarning(description) {
    //   this.alert.status = "warn";
    //   this.alert.title = "Warning";
    //   this.alert.description = description;
    //   this.alert.show = true;
    //   const self = this;
    //   setTimeout(function () {
    //     self.alert.show = false;
    //   }, 3000);
    // },
    // alertError(description) {
    //   this.alert.status = "fail";
    //   this.alert.title = "Error";
    //   this.alert.description = description;
    //   this.alert.show = true;
    //   const self = this;
    //   setTimeout(function () {
    //     self.alert.show = false;
    //   }, 5000);
    // },
  },
  async created() {
    // this.getCentres();
    this.getBranches();
    this.getGroupRoleNames();
    this.getCustomers();
    this.getCustomerTypes();
  },
  filters: {
    formatDatePipe: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    }
  },
  computed: {
    ...mapState({
      // user: (state) => state.auth.user,
      groupTypes: (state) => state.deposit?.groupTypes?.data?.items,
    }),

    // userCanEditGroup() {
    //   const group = this.user.scopes.find(item => item.scopeName === Role.Group);
    //   const { permissionsList } = group;
    //   return permissionsList.CanEditGroup;
    // },
  }
});
</script>
<style scoped>
.move {
  cursor: move;
}
</style>

