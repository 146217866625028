<template>
  <div class="page__content--wider">
    <div class="page__head2">
      <div
        class="
          page__head__container
          ml-auto
          align-items-center
          justify-content-between
        "
      >
        <div class="page__head__container">
          <div class="page__head__icon">
            <img src="assets/img/page-icon.svg" alt="" srcset="" />
          </div>
          <div class="page__head__text">
            <h1 class="page__head__title">Tasks</h1>
            <p class="page__head__subtitle">List of all your group task created</p>
          </div>
        </div>
        <div class="page__head__actions">
          <div class="d-flex">
            <div class="page__head__actions flex-grow-1">
              <div class="d-flex align-items-center justify-content-starts">
                <!-- <label
                  class="
                    text--black text--opaq2 text--small
                    d-block
                    text--bold text--capital
                    w-76
                    mr-2
                  "
                  style="width:76%"
                  >Task Status:</label
                > -->
                <select
                  @change="getTasks"
                  class="select--lg w-35"
                  v-model="fetchModel.taskStatus"
                  style="height: 39px"
                >
                  <option selected disabled :value="null">Select Status</option>
                  <option selected value="">All</option>
                  <option value="COMPLETED">Completed</option>
                  <option value="OPENED">Opened</option>
                </select>
              </div>
            </div>

            <button
              type="button"
              class="
                mx-2
                button
                page__body__header__button
                button--grey
                text--capital
              "
              v-if="userCanCreateTask"
              data-target="#newTaskModal"
              data-toggle="modal"
            >
              <span class="d-inline-flex mr-2">new task</span>
            </button>

            <div class="align-self-center mx-2 dropdown pb-3">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                style="height: 38px"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                export
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  href="javascript:void(0)"
                  class="dropdown-item text--capital"
                  v-if="userCanExportToPDF"
                  @click="pdfDownload"
                  >PDF</a
                >
                <a
                  href="javascript:void(0)"
                  @click="processDownload"
                  v-if="userCanExportToExcel"
                  class="dropdown-item text--capital"
                  >excel</a
                >
                <!-- <download-excel
                  class="dropdown-item text--capital"
                  style="cursor: pointer"
                  :data="download"
                  :fields="json_fields"
                  name="group_tasks.xls"
                >
                  excel
                </download-excel> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page__body">
      <!-- <div class="page__body__header">
        <div
          class="
            page__head__container
            align-items-center
            justify-content-between
          "
        >
          <div class="page__head__text flex-fill">
            <h1 class="page__head__title">Tasks</h1>
          </div>
          <div class="page__head__actions flex-grow-1">
            <div class="d-flex align-items-center justify-content-end">
              <label
                class="
                  text--black text--opaq2 text--small
                  d-block
                  text--bold text--capital
                  w-25
                "
                >Task Status:</label
              >
              <select
                @change="getTasks"
                class="select--lg w-75"
                v-model="fetchModel.taskStatus"
              >
                <option selected value="">All</option>
                <option value="COMPLETED">Completed</option>
                <option value="OPEN">Open</option>
              </select>
            </div>
          </div>
        </div>
      </div> -->

      <div class="row ml-3">
        <table class="customtable w-100">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 20%"
              >
                title
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                status
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                linked To
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 18%"
              >
                due created
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 25%"
              >
                note
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                actions
              </th>
            </tr>
          </thead>
          <tbody v-if="tasks.length">
            <tr v-for="item in tasks" v-bind:key="item.taskKey">
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.taskTitle
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.taskStatus
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.linkToName
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.dueDate | formatShortDatePipe
                }}</span>
              </td>
              <td>
                <a
                  class="
                    table__body__text table__text
                    text--capital text--success
                  "
                  @click="openTaskNote(item)"
                >
                  <img src="@/assets/img/paper-green.svg" alt="" srcset="" />
                  View Note
                </a>
              </td>
              <td>
                <div class="align-self-center dropdown">
                  <button
                    class="
                      button button--grey
                      text--capital text--small
                      d-flex
                      align-items-center
                    "
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    action
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#editTaskModal"
                      v-on:click="editTask(item.taskKey)"
                      >edit</a
                    >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#deleteConfirmModal"
                      v-on:click="openDeleteConfirmation(item)"
                      >delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12">
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    p5
                  "
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="d-block text--danger text--600 text--medium mt-3 mb-5"
                    >No data available</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        :allItems="paging"
        :size="fetchModel.pageSize"
        @update-created-task="getUpdated"
        @change-page-index="changePageIndex"
      />
    </div>

    <!-- Modals -->
    <div
      class="modal modal__right"
      id="newTaskModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon" v-show="!isSaving">
              <a
                data-dismiss="modal"
                id="newTaskModalClose"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Add a Task</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveTaskForm">
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Summary</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="taskForm.taskTitle"
                      v-bind:class="{
                        'input-required': taskFormErr.taskTitle,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-6 form__item d-none">
                  <div class="form-group">
                    <label class="form__label">Unique ID</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="taskForm.uniqueTaskId"
                      v-bind:class="{
                        'input-required': taskFormErr.uniqueTaskId,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Linked To</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      readonly
                      :value="taskLinkName"
                    />
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <label class="form__label">Assigned To</label>
                  <multiselect
                    v-model="assignedPerson"
                    id="ajax"
                    label="name"
                    track-by="name"
                    placeholder="Find Users"
                    open-direction="bottom"
                    :options="users"
                    :custom-label="name"
                    :multiple="false"
                    :searchable="true"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :limit-text="limitText"
                    :max-height="800"
                    :show-no-results="false"
                    :hide-selected="true"
                    v-bind:class="{
                      'input-required': taskFormErr.assignedUserKey,
                    }"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">
                        <span>{{ option.name }}</span>
                        <span class="custom__remove" @click="remove(option)"
                          >❌</span
                        >
                      </span>
                    </template>
                  </multiselect>
                  <!--<select
                    class="select--lg w-100"
                    v-model="taskForm.assignedUserKey"
                    v-bind:class="{
                      'input-required': taskFormErr.assignedUserKey,
                    }"
                  >
                    <option disabled></option>
                  </select>-->
                </div>
                <div class="col-md-6 form__item">
                  <label class="form__label">Due Date</label>
                  <input
                    v-model="taskForm.dueDate"
                    class="input form__input w-100"
                    placeholder="Enter date"
                    type="date"
                    :min="currentDate"
                    v-bind:class="{
                      'input-required': taskFormErr.dueDate,
                    }"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 form__item">
                  <label class="form__label">Notes</label>
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder="Profile Note"
                    v-model="taskForm.taskDescription"
                  ></textarea>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                  >
                    Save Task
                  </button>

                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editTaskModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon" v-show="!isSaving">
              <a
                data-dismiss="modal"
                id="editTaskModalClose"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit a Task</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveTaskEditForm">
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Summary</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="taskEditForm.taskTitle"
                      v-bind:class="{
                        'input-required': taskEditFormErr.taskTitle,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-6 form__item d-none">
                  <div class="form-group">
                    <label class="form__label">Unique ID</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="taskEditForm.uniqueTaskId"
                      v-bind:class="{
                        'input-required': taskEditFormErr.uniqueTaskId,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Linked To</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      :value="taskLinkName"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <label class="form__label">Assigned To</label>
                  <select
                    class="select--lg w-100"
                    v-model="taskEditForm.assignedUserKey"
                    v-bind:class="{
                      'input-required': taskEditFormErr.assignedUserKey,
                    }"
                  >
                    <option disabled>__Select user__</option>
                    <option
                      :value="user.userKey"
                      v-for="user in users"
                      :key="user.id"
                      :selected="taskEditForm.assignedUserKey"
                    >
                      {{ user.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 form__item">
                  <label class="form__label">Due Date</label>
                  <input
                    v-model="taskEditForm.dueDate"
                    class="input form__input w-100"
                    placeholder="Enter date"
                    type="date"
                    v-bind:class="{
                      'input-required': taskEditFormErr.dueDate,
                    }"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 form__item">
                  <label class="form__label">Notes</label>
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder="Profile Note"
                    v-model="taskEditForm.taskDescription"
                  ></textarea>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                  >
                    Save Task
                  </button>

                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div class="modal__dialog" role="document" v-if="taskToDelete != null">
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this task
                  {{ taskToDelete.taskTitle }} ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this task from your list, you won't be able to
                  access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="
                  button
                  form__button form__button--lg
                  mr-5
                  modal__confirm__button modal__confirm__button--cancel
                "
                data-dismiss="modal"
                aria-label="Close"
                id="deleteCloseButton"
              >
                Cancel
              </button>

              <!-- loader button -->
              <a
                role="button"
                class="
                  button
                  form__button form__button--lg
                  w-25
                  modal__confirm__button modal__confirm__button--delete
                "
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="
                  button
                  form__button form__button--lg
                  modal__confirm__button modal__confirm__button--delete
                "
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteTask()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
      :show="!userCanViewTask"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Unauthorized
            </h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">

              <button
                data-dismiss="#confirmModal"
                class="
                  button
                  form__button form__button--lg
                  save-changes
                  fitrem
                "
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <viewTaskNote
      :task="iTask"
      :show="showTaskNoteModal"
      @close="closeTaskNote"
      @task-completed-successfully="taskCompleted"
      @error-completing-task="taskError"
    />

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import taskService from "@/core/services/general-setup-service/task.service";
import customerService from "@/core/services/general-setup-service/customer.service";
import groupService from "@/core/services/general-setup-service/group.service";
import router from "@/router.js";
import moment from "moment";
import * as XLSX from "xlsx";
// import JsonExcel from "vue-json-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/access.service";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";

export default {
  name: "TaskView",
  props: ["taskLinkKeyProp", "taskLinkTypeProp"],
  components: {
    // downloadExcel: JsonExcel,
    Multiselect,
    DeleteModal: () =>
      import(
        /* WebpackChunkName: "viewTaskNote" */ "@/components/DeleteModal/_base-delete-modal.vue"
      ),
    viewTaskNote: () =>
      import(
        /* WebpackChunkName: "viewTaskNote" */ "@/view/pages/deposit/task/viewTaskNote.vue"
      ),
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
  },
  data: function () {
    return {
      assignedPerson: null,
      users: [],
      taskLinkKey: this.taskLinkKeyProp,
      taskLinkType: this.taskLinkTypeProp,
      groupKey: this.$route.params.groupKey,
      taskLinkName: null,
      taskLinkTypes: [
        "CLIENT",
        "GROUP",
        "LOAN_PRODUCT",
        "SAVINGS_PRODUCT",
        "CENTRE",
        "BRANCH",
        "USER",
        "LOAN_ACCOUNT",
        "DEPOSIT_ACCOUNT",
      ],
      json_fields: {
        "Title": "title",
        "Status": "status",
        "Linked To": "linked_to",
        "Due Date": "due_date",
        "Note": "note",
      },
      taskStatuses: ["OPEN", "COMPLETED"],
      customerInfo: {
        customerKey: null,
        assignedBranchKey: null,
        assignedCentreKey: null,
        assignedUserKey: null,
        customerTypesKey: null,
        customerBirthDate: null,
        customerEmailAddress: null,
        customerFirstName: null,
        customerGender: null,
        uniqueCustomerId: null,
        customerLastName: null,
        customerMiddleName: null,
        customerMobilePhone1: null,
        customerNotes: null,
        preferredLanguage: null,
        customerStatus: null,
        creationDate: null,
        lastModifiedDate: null,
        createdBy: null,
        modifiedBy: null,
        customFieldSetFieldsAndValue: [],
      },
      customerKey: null,
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      // Task
      tasks: [],
      taskToDelete: null,
      taskForm: {
        assignedUserKey: null,
        createdByUserKey: null,
        taskDescription: null,
        taskTitle: null,
        // uniqueTaskId: null,
        taskLinkKey: null,
        taskLinkType: "CLIENT",
        dueDate: null,
      },
      taskFormErr: {
        assignedUserKey: false,
        createdByUserKey: false,
        taskDescription: false,
        taskTitle: false,
        // uniqueTaskId: false,
        taskLinkKey: false,
        taskLinkType: false,
        dueDate: false,
      },
      taskEditForm: {
        taskKey: null,
        assignedUserKey: null,
        createdByUserKey: null,
        taskDescription: null,
        taskTitle: null,
        // uniqueTaskId: null,
        taskLinkKey: null,
        taskLinkType: null,
        dueDate: null,
      },
      taskEditFormErr: {
        assignedUserKey: false,
        createdByUserKey: false,
        taskDescription: false,
        taskTitle: false,
        // uniqueTaskId: false,
        taskLinkKey: false,
        taskLinkType: false,
        dueDate: false,
      },
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      fetchModel: {
        pageIndex: 1,
        pageSize: 10,
        linkedEntityKey: null,
        type: null,
        taskStatus: null,
      },
      currentDate: moment().format("YYYY-MM-DD"),
      iTask: {},
      showTaskNoteModal: false,
      // End Task
    };
  },
  methods: {
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Group Tasks Download",
        Subject: "Group Tasks Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("GroupTasks");

      let sheetData = [["Title", "Status", "Linked To", "Due Date", "Note"]];
      if (this.tasks) {
        this.tasks.map((item) => {
          let new_arr = [];

          new_arr.push(item.taskTitle);
          new_arr.push(moment(item.dueDate).format("Do-MMM-YYYY"));
          new_arr.push(item.taskStatus);
          new_arr.push(item.linkToName);
          new_arr.push(item.taskDescription);

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["GroupTasks"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "group_tasks.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Title", dataKey: "title"},
        { title: "Status", dataKey: "status"},
        { title: "Linked To", dataKey: "linked_to" },
        { title: "Due Date", dataKey: "due_date"},
        { title: "Note", dataKey: "note"},
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("group_tasks.pdf");
    },
    limitText(count) {
      return `and ${count} other customers`;
    },
    goToDashboard() {
      this.$router.push({ path: '/dashboard' });
    },
    name({ name }) {
      return `${name}`;
    },
    getUsers() {
      const page = {
        pageNumber: 1,
        pageSize: 10000,
        status: true,
        filters: [],
      }
      ApiService.post(
        "ProleanUser/GetUsersWithoutPermission", page
      ).then((response) => {
        this.users = response.data.data;
      });
    },
    async getTasks() {
      this.isLoading = true;
      if ((this.taskLinkKey && this.taskLinkType) || this.groupKey) {
        try {
          const res = await taskService.getTasks(this.fetchModel);
          this.tasks = res.data.data.items;
          this.paging = res.data.data;
          this.paging.currentPage = this.paging.pageIndex;
          this.isLoading = false;
        } catch (e) {
          this.isLoading = false;
        }
      } else {
        router.pop();
      }
      if (!this.userCanViewTask) return this.alertWarning('You do not have permission to view this page');
    },
    getTaskByKey(key) {
      try {
        this.isLoading = true;
        taskService.getTaskByKey(key).then((res) => {
          this.taskEditForm = res.data.data;
          this.taskEditForm.dueDate = this.getDate(this.taskEditForm.dueDate);
          this.isLoading = false;
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    editTask(taskKey) {
      try {
        this.clearTaskEditForm();
        this.getTaskByKey(taskKey);
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    openTaskNote(value) {
      this.iTask = value;
      this.showTaskNoteModal = !this.showTaskNoteModal;
    },
    closeTaskNote() {
      this.showTaskNoteModal = false;
    },
    taskCompleted(value) {
      this.alertSuccess(value);
      this.getTasks();
    },
    taskError(value) {
      this.alertError(value);
    },
    saveTaskForm: async function (e) {
      e.preventDefault();
      // const dueDate = moment(this.taskForm.dueDate, "YYYY-MM-DD").format(
      //   "DD-MMM-YYYY"
      // );
      // if (moment().isSameOrAfter(dueDate, "day")) {
      //   this.alertWarning("The task due date cannot be a past date");
      // } else 
      if (this.validateTaskForm()) {
        try {
          this.isSaving = true;
          this.taskForm.assignedUserKey = this.assignedPerson.userKey;
          await taskService.create(this.taskForm);
          this.isSaving = false;
          document.getElementById("newTaskModalClose").click();
          this.clearTaskForm();
          this.assignedPerson = ""
          this.getTasks();
          this.alertSuccess("Task added.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    saveTaskEditForm: async function (e) {
      e.preventDefault();
      // const dueDate = moment(this.taskEditForm.dueDate, "YYYY-MM-DD").format(
      //   "DD-MMM-YYYY"
      // );
      // if (moment().isSameOrAfter(dueDate, "day")) {
      //   this.alertWarning("The task due date cannot be a past date");
      // } else 
      if (this.validateTaskEditForm()) {
        try {
          this.isSaving = true;
          await taskService.update(
            this.taskEditForm.taskKey,
            this.taskEditForm
          );
          this.isSaving = false;
          document.getElementById("editTaskModalClose").click();
          this.clearTaskEditForm();
          this.getTasks();
          this.alertSuccess("Task updated.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    openDeleteConfirmation(task) {
      this.taskToDelete = task;
    },
    async deleteTask() {
      if (this.taskToDelete != null) {
        this.isDeleting = true;
        try {
          await taskService.delete(this.taskToDelete.taskKey);
          this.taskToDelete = null;
          document.getElementById("deleteCloseButton").click();
          this.alertSuccess("Task deleted");
          this.isDeleting = false;
          this.getTasks();
        } catch (e) {
          this.isDeleting = false;
          const error = e.response.data.message || "Something went wrong";
          this.alertError(error);
        }
      }
    },

    validateTaskForm() {
      this.clearTaskValidation();
      if (
        this.taskForm.taskTitle &&
        this.taskForm.dueDate
        // &&
        // this.taskForm.uniqueTaskId &&
        // this.taskForm.assignedUserKey
      ) {
        return true;
      }

      if (!this.taskForm.taskTitle) {
        this.taskFormErr.taskTitle = true;
      }
      if (!this.taskForm.dueDate) {
        this.taskFormErr.dueDate = true;
      }
      // if (!this.taskForm.uniqueTaskId) {
      //   this.taskFormErr.uniqueTaskId = true;
      // }
      if (!this.taskForm.assignedUserKey) {
        this.taskFormErr.assignedUserKey = true;
      }
    },
    clearTaskForm() {
      this.taskForm = {
        assignedUserKey: null,
        createdByUserKey: null,
        taskDescription: null,
        taskTitle: null,
        // uniqueTaskId: null,
        taskLinkKey: this.taskLinkKey,
        taskLinkType: this.taskLinkType,
        dueDate: null,
      };
    },
    clearTaskValidation() {
      this.taskFormErr = {
        assignedUserKey: false,
        createdByUserKey: false,
        taskDescription: false,
        taskTitle: false,
        // uniqueTaskId: false,
        taskLinkKey: false,
        taskLinkType: false,
        dueDate: false,
      };
    },

    validateTaskEditForm() {
      this.clearTaskEditValidation();
      if (
        this.taskEditForm.taskTitle &&
        this.taskEditForm.dueDate &&
        // this.taskEditForm.uniqueTaskId &&
        this.taskEditForm.assignedUserKey
      ) {
        return true;
      }

      if (!this.taskEditForm.taskTitle) {
        this.taskEditFormErr.taskTitle = true;
      }
      if (!this.taskEditForm.dueDate) {
        this.taskEditFormErr.dueDate = true;
      }
      // if (!this.taskEditForm.uniqueTaskId) {
      //   this.taskEditFormErr.uniqueTaskId = true;
      // }
      if (!this.taskEditForm.assignedUserKey) {
        this.taskEditFormErr.assignedUserKey = true;
      }
    },
    clearTaskEditForm() {
      this.taskFormEdit = {
        taskKey: null,
        assignedUserKey: null,
        createdByUserKey: null,
        taskDescription: null,
        taskTitle: null,
        // uniqueTaskId: null,
        taskLinkKey: this.taskLinkKey,
        taskLinkType: this.taskLinkType,
        dueDate: null,
      };
    },
    clearTaskEditValidation() {
      this.taskEditFormErr = {
        assignedUserKey: false,
        createdByUserKey: false,
        taskDescription: false,
        taskTitle: false,
        // uniqueTaskId: false,
        taskLinkKey: false,
        taskLinkType: false,
        dueDate: false,
      };
    },
    getUpdated(value) {
      this.fetchModel.pageSize = value;
      this.getTasks();
    },
    changePageIndex(value, pageSize) {
      this.changeCurrentPage(value, pageSize);
    },
    changeCurrentPage(pages = null) {
      if (pages === "next") {
        this.fetchModel.pageIndex = this.paging.hasNextPage
          ? this.paging.currentPage + 1
          : 0;

        this.getTasks();
      } else if (pages === "previous") {
        this.fetchModel.pageIndex = this.paging.hasPreviousPage
          ? this.paging.currentPage - 1
          : 0;

        this.getTasks();
      } else if (pages !== null) {
        this.fetchModel.pageIndex = pages;
        
        this.getTasks();
      }
    },
    getDate(s) {
      try {
        let d = new Date(s);
        const yyyy = `${d.getFullYear()}`;
        const mm = `0${d.getMonth() + 1}`.slice(-2);
        const dd = `0${d.getDate()}`.slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      } catch (error) {
        return false;
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    init() {
      if (
        this.$route.params.taskLinkType != null &&
        this.$route.params.taskLinkKey != null
      ) {
        this.taskLinkType = this.$route.params.taskLinkType;
        this.taskLinkKey = this.$route.params.taskLinkKey;
      }
      this.fetchModel.type = this.taskLinkType;
      this.fetchModel.linkedEntityKey = this.taskLinkKey;
      this.taskForm = {
        assignedUserKey: null,
        createdByUserKey: null,
        taskDescription: null,
        taskTitle: null,
        uniqueTaskId: null,
        taskLinkKey: this.taskLinkKey,
        taskLinkType: this.taskLinkType,
        dueDate: null,
      };
      this.taskEditForm = {
        taskKey: null,
        assignedUserKey: null,
        createdByUserKey: null,
        taskDescription: null,
        taskTitle: null,
        uniqueTaskId: null,
        taskLinkKey: this.taskLinkKey,
        taskLinkType: this.taskLinkType,
        dueDate: null,
      };

      switch (this.taskLinkType) {
        case "CLIENT":
          customerService.getCustomerByKey(this.taskLinkKey).then((res) => {
            const customer = res.data.data;
            this.taskLinkName = `${customer.customerFirstName} ${customer.customerLastName}`;
          });
          break;
        case "GROUP":
          groupService.getGroupByKey(this.taskLinkKey).then((res) => {
            const group = res.data.data;
            this.taskLinkName = group.groupName;
          });
          break;

        default:
          break;
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateTask() {
      const task = this.user.scopes.find(item => item.scopeName === Role.Tasks);
      const { permissionsList } = task;
      return permissionsList.CanCreateTask;
    },
    userCanViewTask() {
      const task = this.user.scopes.find(item => item.scopeName === Role.Tasks);
      const { permissionsList } = task;
      return permissionsList.CanViewTask;
    },
    userCanExportToExcel() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
    download() {
      let data = [];
      if(this.tasks) {
        this.tasks.forEach((item) => {
          let new_obj = {};
          new_obj.title = item.taskTitle;
          new_obj.due_date = moment(item.dueDate).format("Do-MMM-YYYY");
          new_obj.status = item.taskStatus;
          new_obj.linked_to = item.linkToName;
          new_obj.note = item.taskDescription;

          data.push(new_obj);
        });
      }

      return data;
    },
  },
  async created() {
    this.init();
    this.getTasks();
    this.getUsers();
  },
  filters: {
    formatDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY : HH:MM");
    },
    formatShortDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
  },
};
</script>
