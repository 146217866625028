import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "Group";
const groupService = {
  getGroups(model) {
    // return ApiService.get(resource, "");
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/Groups`, model);
    }
  },

  getGroupByKey(key) {
    // return ApiService.get(resource, "");
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, key);
    }
  },

  createGroup(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}`, data);
    }
  },

  updateGroup(key, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(resource, key, data);
    }
  },

  getGroupRoleNames({ pageSize, pageIndex }) {
    // return ApiService.get(resource, "");
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, `GroupRoleNames?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
  },

  createGroupRoleName(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/GroupRoleName`, data);
    }
  },

  updateGroupRoleName(key, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(resource, `${key}/GroupRoleName`, data);
    }
  },

  deleteGroupRoleName(id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}/${id}/GroupRoleName`, null);
    }
  },
};

export default groupService;
