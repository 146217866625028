import ApiService from "@/core/services/api.service";
import LoanApiService from "@/core/services/api.loan.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const otherService = {
  getDepositByCustomer(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(
        `DepositAccount/GetDepositAccountByCustomer/${key}`
      );
    }
  },
  getAllLoanDepositAccount(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(
        `DepositAccount/GetAllLoanDepositAccount/${key}`
      );
    }
  },
  getAllDepositAccounts(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(
        `DepositAdministration/GetActiveDeactiveDepositProduct?PageIndex=${model.pageNum}&PageSze=${model.pageSize}&SortBy=Name&Status=&p`
      );
    }
  },
  getAllLoanProducts() {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      // return ApiService.get(`${loanUrl}/LoanProduct/get-all-loanproducts`);
      return LoanApiService.get(
        `LoanProduct/getloanproducts?pageindex=1&pagesize=10000000&sortColumn=product_name&sortDesending=false`
      );
    }
  },
  getAllCustomers(model) {
    ApiService.setHeader();
    return ApiService.get(
      `DepositAccount/GetFilteredDepositProduct?pageIndex=${model.pageIndex}&pageSize=${model.pageSize}`
    );
  },

  //#region LoanProduct
  getLoanProductById(key) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.get(`LoanProduct/getloanproduct/${key}`);
    }
  },
  //#endregion

  //#region LoanAccount
  getLoanAccountDetailsById(key) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.get(
        `LoanAccount/getloanaccountdetailsbyloanaccountid/${key}`
      );
    }
  },
  getLoanAccountDetailsScheduleById(model) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.get(
        `LoanAccount/get-loanaccount-schedule/${model.loanAccountId}`
      );
    }
  },
  getLoanAccountDetailsTransactionsById(key) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.get(
        `LoanAccount/getloanaccountdetailstransactionsbyloanaccountid/${key}/false`
      );
    }
  },
  getLoanAccountDetailsActivitiesById(key) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.get(
        `LoanAccount/getloanaccountdetailsactivitiesbyloanaccountid/${key}`
      );
    }
  },
  getLoanAccountDetailsAttachmentsById(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(
        `GeneralDocument/getUploadedDocumentByHolderkey?holderkey=${key}&pageIndex=1&pageSize=20`
      );
    }
  },
  getLoanAccountDetailsCommentsById(key) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.get(
        `LoanAccount/getloanaccountdetailscommentsbyloanaccountid/${key}`
      );
    }
  },
  regenerateRepaymentSchedule(model) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.patch(
        `LoanAccount/getrepaymentschedule`,
        model
      );
    }
  },

  editLoanAccountDetails(model) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.patch(
        `LoanAccount/edit-loan-account-details`,
        model
      );
    }
  },
  editLoanAccount(model) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.patch(`LoanAccount/editloanaccount`, model);
    }
  },
  editLoanAmount(model) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.post(`LoanAccount/editloanamount`, model);
    }
  },
  editInterestRate(model) {
    if (JwtService.getToken()) {
      LoanApiService.setHeader();
      return LoanApiService.post(
        `LoanAccount/editloaninterestrate`,
        model
      );
    }
  },
  //#endregion
};

export default otherService;
